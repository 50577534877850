import React, { useState, useEffect } from 'react';
import styles from './Search.module.css';
import axios from 'axios';
import { BiSearch } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaRegFaceGrinWink } from "react-icons/fa6";
import { BsCart2 } from "react-icons/bs";
import { Loader } from "../../components/Loader/Loader";
import { FaQuestion } from "react-icons/fa";
import { MobileMenu } from '../../components/MobileMenu/MobileMenu';

export const Search = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [fileData, setFileData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const token = localStorage.getItem("token");

  const handleSearch = async (searchTerm) => {
    if (!searchTerm.trim()) {
      setSearchResults([]);
      setSearchPerformed(false);
      return;
    }

    setIsLoading(true);
    setSearchPerformed(true); // Устанавливаем, что поиск был выполнен
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_MAIN_URL}/product/search/?search=${searchTerm}`
      );

      const data = response.data.data;
      if (Array.isArray(data)) {
        setSearchResults(data);
      } else {
        setSearchResults([]);
        console.error('Ожидался массив, получен другой тип данных');
      }
    } catch (error) {
      setError('Ошибка при загрузке данных');
      console.error('Ошибка при загрузке данных:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleSearch(searchTerm);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handleAddToCart = async (productId) => {
    const token = localStorage.getItem('token');
    if (!token) {
      toast.error('Пожалуйста, войдите в систему');
      return;
    }

    try {
      const existingItem = cartItems.find(item => item.product === productId);

      if (existingItem) {
        await axios.put(
          `${process.env.REACT_APP_MAIN_URL}/order/basket/items/${existingItem.id}/update/`,
          { quantity: existingItem.quantity + 1 },
          { headers: { Authorization: `token ${token}` } }
        );
        setCartItems(cartItems.map(item =>
          item.product === productId
            ? { ...item, quantity: item.quantity + 1 }
            : item
        ));
        toast.success('Количество товара обновлено в корзине');
      } else {
        const response = await axios.post(
          `${process.env.REACT_APP_MAIN_URL}/order/basket/items/create/`,
          { product: productId, quantity: 1 },
          { headers: { Authorization: `token ${token}` } }
        );
        setCartItems([...cartItems, { ...response.data }]);
        toast.success('Товар успешно добавлен в корзину');
      }
    } catch (error) {
      console.error('Error in handleAddToCart:', error);
      toast.error('Ошибка при добавлении товара в корзину');
    }
  };

  const fetchFiles = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_MAIN_URL}/file/`
      );
      setFileData(response.data);
    } catch (error) {
      console.error('Ошибка при загрузке файлов: ', error);
    }
  };

  const getProductImage = (productId) => {
    const file = fileData.find(file => file.product === productId && file.main_file);
    return file ? file.file : null;
  };


  useEffect(() => {
    window.scrollTo(0, 0); // Прокрутка вверх при каждом изменении id

    fetchFiles();
  }, []);

  return (
    <div>
      <div className={styles.main}>
        <div className={styles.search}>
          <input
            type="text"
            placeholder="Поиск..."
            className={styles.search_input}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button className={styles.search_btn}>
            <BiSearch className={styles.searchIcon} />
          </button>
        </div>

        {error && <p>{error}</p>}

        {isLoading ? (
          <Loader />
        ) : searchTerm === '' ? (
          <p className={styles.emptyCartMessage}>
            Какой продукт вы ищете <FaQuestion className={styles.smile} />
          </p>
        ) : searchResults.length === 0 && searchPerformed ? (
          <p className={styles.emptyCartMessage}>
            Продукт не найден <FaRegFaceGrinWink className={styles.smile} />
          </p>
        ) : (
          <ul className={styles.productContainer}>
            {searchResults.map((product) => {
              const productImage = getProductImage(product.id);

              return (
                <div key={product.id} className={styles.productCard}>
                  {product.discount ? (
                    <p className={styles.discountL}>
                      {product.discount_percentage}%
                    </p>
                  ) : (
                    <p className={styles.discountL} style={{ visibility: 'hidden' }}>
                      0%
                    </p>
                  )}
                  <Link to={`/product/${product.id}`}>
                    <img
                      src={productImage}
                      alt={product.name}
                      className={styles.productImage}
                    />
                    <h3 className={styles.productTitle}>{product.name}</h3>
                  </Link>
                  <div className={styles.priceBlock}>
                    <div className="">
                      {product.old_price > 0 ? (
                        <p className={styles.productOldPrice}>
                          <span>{product.old_price} сом</span>
                        </p>
                      ) : (
                        <p className={styles.productOldPrice} style={{ visibility: 'hidden' }}>
                          0 сом
                        </p>
                      )}
                      <p className={styles.productPrice}>
                        {product.price} сом
                      </p>
                    </div>
                    <button className={styles.btn} onClick={() => handleAddToCart(product.id)}><BsCart2 /></button>
                  </div>
                </div>
              );
            })}
          </ul>
        )}
      </div>
      <MobileMenu />
    </div>
  );
};
