import React, { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { IoCart } from "react-icons/io5";
import { FaUser } from "react-icons/fa";
import { IoHome } from "react-icons/io5";
import { Link } from "react-router-dom";
import styles from './MobileMenu.module.css'

export const MobileMenu = () => {
    const [token, setToken] = useState(null);

    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        setToken(storedToken);
      }, []);
    

    return (
        <div className={styles['mobile-menu']}>
            <nav>
                <ul>
                    <Link to={'/'} className={styles.mb}>
                        <IoHome className={styles.icon} />
                        <a className={styles.mbNav}>Главная</a>
                    </Link>

                    <Link to={token ? '/profile' : '/login'} className={styles.mb} data-title="Кабинет">
                        <FaUser className={styles.icon} />
                        <a className={styles.mbNav}>Кабинет</a>
                    </Link>

                    <Link to="/search" className={styles.mb}>
                        <FaSearch className={styles.icon} />
                        <a className={styles.mbNav}>Поиск</a>
                    </Link>

                    <Link className={styles.mb} to={token ? '/basket' : '/login'} >
                        <IoCart className={styles.icon} />
                        <a className={styles.mbNav}>Корзина</a>
                    </Link>
                    
                </ul>
            </nav>
        </div>
    );
};

