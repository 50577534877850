import React, { useEffect, useState } from "react";
import styles from "./Profile.module.css";
import user from "../../images/user2.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-modal";
import Sidebar from "../../components/Sidebar/Sidebar";
import { MobileMenu } from '../../components/MobileMenu/MobileMenu'
Modal.setAppElement("#root");

export const Profile = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [openBasket, setOpenBasket] = useState(false);
  const showBasketDrawer = () => setOpenBasket(true);
  const closeBasketDrawer = () => setOpenBasket(false);
  const showMenuDrawer = () => setOpenMenu(true);
  const closeMenuDrawer = () => setOpenMenu(false);
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const [newName, setNewName] = useState("");
  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("token"));


  const fetchUserData = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      const headers = new Headers({
        Authorization: `token ${token}`,
      });

      try {
        const response = await fetch(
          `${process.env.REACT_APP_MAIN_URL}/users/detail/`,
          { headers }
        );
        if (!response.ok) {
          throw new Error("Ошибка при получении данных пользователя");
        }
        const data = await response.json();
        setUserData(data);
        setIsSuperUser(data.is_staff);
      } catch (error) {
        console.error("Ошибка при получении данных пользователя:", error);
      }
    }
  };

  const logout = async () => {
    const token = localStorage.getItem("token");
  
    if (!token) {
      console.error("Ошибка: Токен не найден.");
      return;
    }
  
    try {
      const response = await fetch(`${process.env.REACT_APP_MAIN_URL}/users/logout/`, {
        method: "POST",
        headers: {
          Authorization: `token ${token}`,
          "Content-Type": "application/json",
        },
      });
  
      if (response.ok) {
        localStorage.removeItem("token");
        localStorage.removeItem("isLoggedIn");
        setToken(null);
        navigate("/");
        toast.success("Вы успешно вышли из аккаунта");
      } else {
        toast.error("Ошибка при выходе из аккаунта");
        console.error("Ошибка при выполнении logout:", response.statusText);
      }
    } catch (error) {
      toast.error("Произошла ошибка при выходе из аккаунта");
      console.error("Произошла ошибка:", error);
    }
  };
  


  const openModal = () => {
    setModalIsOpen(true);
    setNewName(userData.first_name);
    setNewPhoneNumber(userData.email);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setNewName("");
    setNewPhoneNumber("");
  };

  const handleUpdate = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Ошибка: Токен не найден.");
      return;
    }

    const requestData = {
      first_name: newName,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_MAIN_URL}/users/update/`,
        {
          method: "PATCH",
          headers: {
            Authorization: `token ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );
      if (response.ok) {
        toast.success("Данные успешно изменены");
        closeModal();
        fetchUserData();
      } else {
        toast.error("Упс ошибка");
        console.error("Ошибка при обновлении данных:", response.statusText);
      }
    } catch (error) {
      toast.error("Упс ошибка");
      console.error("Произошла ошибка при обновлении данных:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Прокрутка вверх при каждом изменении id

    fetchUserData();
    setToken(localStorage.getItem("token"));
  }, []);

  return (
    <div className={styles.allContent}>
      <div className={styles.content}>

        <Sidebar showMenuDrawer={showMenuDrawer} isSuperUser={isSuperUser} />

        <div className={styles.main}>
          <div className={styles.title}>
            <h1 className={styles.subtitle}>
              Добро пожаловать в личный кабнет
            </h1>
          </div>

          <div className={styles.userDetail}>
            <div className={styles.userData}>
              <div className={styles.User}>
                <img src={user} alt="" className={styles.userLogo} />
              </div>
              <div className={styles.data}>
                <h3 className={styles.dataUser}>
                  <p>
                    Имя:
                    <span>{userData.first_name}</span>
                  </p>
                </h3>
                <h3 className={styles.dataUser}>
                  <p>
                    Email:
                    <span> {userData.email}</span>
                  </p>
                </h3>
              </div>
            </div>
            <div className={styles.btn}>
              <button onClick={openModal}>изменить</button>
              <button onClick={logout}>выйти</button>
            </div>
          </div>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Редактировать данные"
            className={styles.ModalEdit}
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.3)",
              },
              content: {
                margin: "auto",
                padding: "0 0 10px 0",
                borderRadius: "10px",
                border: "none",
              },
            }}
          >
            <h2 className={styles.modal_title}>Редактировать данные</h2>
                <h3 className={styles.modal_Subtitle}>Изменение имени</h3>
                <form className={styles.form}>
                  <input
                    type="text"
                    placeholder="Новое имя"
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                  />
                </form>
                <div className={styles.save}>
                  <button onClick={handleUpdate}>Сохранить</button>
                </div>
          </Modal>
        </div>
      </div>
      <MobileMenu />
      <ToastContainer />
    </div>
  );
};
