import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaUserLarge } from "react-icons/fa6";
import { BsFillBagCheckFill } from "react-icons/bs";
import { AiOutlineAlignRight } from "react-icons/ai";
import { SiAdminer } from "react-icons/si";
import { BsArrowLeftShort } from "react-icons/bs";
import { IoMenuSharp, IoCloseSharp } from "react-icons/io5"; // Добавим иконку закрытия
import styles from "./Sidebar.module.css";

const Sidebar = ({ showMenuDrawer, isSuperUser }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className={styles.sidebar}>
      <button onClick={toggleMenu} className={styles.burgerMenu}>
        {isMenuOpen ? <IoCloseSharp /> : <IoMenuSharp />} {/* Иконка изменяется */}
      </button>
      <ul className={`${styles.list} ${isMenuOpen ? styles.open : ''}`}>
        <span className={styles.sp}>
          <i className={styles.userIcon}>
            <FaUserLarge />
          </i>
          <Link to={"/profile"}>Личные данные</Link>
        </span>
        <span className={styles.sp}>
          <i className={styles.basket}>
            <BsFillBagCheckFill />
          </i>
          <Link to={"/basket"}>Текушая корзина</Link>
        </span>
        <span className={styles.sp}>
          <i className={styles.order}>
            <AiOutlineAlignRight />
          </i>
          <Link to={"/myorder"}>Мои заказы</Link>
        </span>
        {isSuperUser && (
          <span className={styles.sp}>
            <i className={styles.exit}>
              <SiAdminer />
            </i>
            <a href="https://webbuy.zero.kg/admin/" target="_blank">
              Админка
            </a>
          </span>
        )}
        <span className={styles.sp}>
          <i className={styles.exit}>
            <BsArrowLeftShort />
          </i>
          <Link to="/">Назад</Link>
        </span>
      </ul>
    </div>
  );
};

export default Sidebar;
