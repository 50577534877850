import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSwipeable } from 'react-swipeable';
import { Link, useParams } from 'react-router-dom';
import Slider from 'react-slick';
import styles from './ProductDetail.module.css';
import { Loader } from '../../components/Loader/Loader';
import { BsCart2 } from "react-icons/bs";
import { MdOutlineNavigateNext } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";
import 'slick-carousel/slick/slick.css';
import { toast, ToastContainer} from 'react-toastify';
import { MobileMenu } from '../../components/MobileMenu/MobileMenu';

const PrevArrow = ({ onClick }) => (
  <button className={`${styles.arrow} ${styles.prev}`} onClick={onClick}>
    <GrFormPrevious />
  </button>
);

const NextArrow = ({ onClick }) => (
  <button className={`${styles.arrow} ${styles.next}`} onClick={onClick}>
    <MdOutlineNavigateNext />
  </button>
);

export const ProductDetail = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [brandProducts, setBrandProducts] = useState([]);
  const [files, setFiles] = useState([]);
  const [productImages, setProductImages] = useState([]);
  const [brand, setBrand] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('overview');
  const [activeImage, setActiveImage] = useState(''); 

  useEffect(() => {
    window.scrollTo(0, 0); // Прокрутка вверх при каждом изменении id
    setIsLoading(true); // Устанавливаем isLoading в true при изменении id

    const fetchProductData = async () => {
      try {
        const [productResponse, fileResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_MAIN_URL}/product/${id}/`),
          axios.get(`${process.env.REACT_APP_MAIN_URL}/file`)
        ]);

        setFiles(fileResponse.data);

        const productFiles = fileResponse.data.filter(file => file.product === productResponse.data.id);
        setProductImages(productFiles);

        const mainFile = productFiles.find(file => file.main_file);
        setActiveImage(mainFile ? mainFile.file : '');

        setProduct(productResponse.data);
        fetchBrandProducts(productResponse.data.brand);
      } catch (error) {
        console.error('Error fetching data', error);
      } finally {
        setIsLoading(false); // Останавливаем индикатор загрузки после получения данных
      }
    };

    const fetchBrandProducts = async (brandId) => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/brand/${brandId}`);
        const filteredProducts = response.data.products.filter(p => p.id !== parseInt(id));
        setBrand(response.data);
        setBrandProducts(filteredProducts);
      } catch (error) {
        console.error('Error fetching brand products', error);
      }
    };

    fetchProductData();
  }, [id]);

  const handleImageClick = (imageUrl) => {
    setActiveImage(imageUrl); 
  };

  const getProductImage = (productId) => {
    const file = files.find(file => file.product === productId && file.main_file);
    return file ? file.file : null;
  };
  

  const handlers = useSwipeable({
    onSwipedLeft: () => handleNextImage(),
    onSwipedRight: () => handlePrevImage(),
  });

  const handleNextImage = () => {
    const currentIndex = productImages.findIndex(file => file.file === activeImage);
    const nextIndex = (currentIndex + 1) % productImages.length;
    setActiveImage(productImages[nextIndex].file);
  };

  const handlePrevImage = () => {
    const currentIndex = productImages.findIndex(file => file.file === activeImage);
    const prevIndex = (currentIndex - 1 + productImages.length) % productImages.length;
    setActiveImage(productImages[prevIndex].file);
  };


  const handleAddToCart = async (productId) => {
    const token = localStorage.getItem('token');
    if (!token) {
      toast.error('Пожалуйста, пройдите регистрацию');
      return;
    }

    try {
      const existingItem = cartItems.find(item => item.product === productId);

      if (existingItem) {
        await axios.put(
          `${process.env.REACT_APP_MAIN_URL}/order/basket/items/${existingItem.id}/update/`,
          { quantity: existingItem.quantity + 1 },
          { headers: { Authorization: `token ${token}` } }
        );
        setCartItems(cartItems.map(item =>
          item.product === productId
            ? { ...item, quantity: item.quantity + 1 }
            : item
        ));
        toast.success('Количество товара обновлено в корзине');
      } else {
        const response = await axios.post(
          `${process.env.REACT_APP_MAIN_URL}/order/basket/items/create/`,
          { product: productId, quantity: 1 },
          { headers: { Authorization: `token ${token}` } }
        );
        setCartItems([...cartItems, { ...response.data }]);
        toast.success('Товар успешно добавлен в корзину');
      }
    } catch (error) {
      console.error('Error in handleAddToCart:', error);
      toast.error('Ошибка при добавлении товара в корзину');
    }
  };

  const createSliderSettings = (numProducts) => ({
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: numProducts > 4,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          arrows: numProducts > 3,
        },
      },
      {
        breakpoint: 920,
        settings: {
          slidesToShow: 2,
          arrows: numProducts > 2,
        }
      },
      {
        breakpoint: 590,
        settings: {
          slidesToShow: 1,
          arrows: numProducts > 1,
        }
      }
    ]
  });

  const formatWarrantyText = (text) => {
    return text.replace(/\r\n/g, '<br />');
  };





  if (isLoading) return <Loader />;

  return (
    <div className={styles.detailContainer}>
      <div className={styles.tabMenu}>
        <button
          className={`${styles.tabButton} ${activeTab === 'overview' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('overview')}
        >
          Обзор товара
        </button>
        <button
          className={`${styles.tabButton} ${activeTab === 'description' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('description')}
        >
          Описание
        </button>
        <button
          className={`${styles.tabButton} ${activeTab === 'warranty' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('warranty')}
        >
          Гарантия
        </button>
      </div>

      {activeTab === 'overview' && (
        <div>
          <h2 className={styles.productTitleDetail}>{product.name}</h2>
          <div className={styles.detailBlock}>

          <div className={styles.imageSlider}>
        <div {...handlers} className={styles.mainSlide}>
          <img src={activeImage} alt={product.name} className={styles.productImageDetail} />
        </div>
        <div className={styles.thumbnailContainer}>
          {productImages.map(file => (
            <img
              key={file.id}
              src={file.file}
              alt={product.name}
              className={`${styles.thumbnailImage} ${file.file === activeImage ? styles.activeThumbnail : ''}`}
              onClick={() => handleImageClick(file.file)}
            />
          ))}
        </div>
      </div>


            <div>
              <div className={styles.BrandCont}>
              {brand && <p className={styles.brandName}>{brand.name}</p>}
              {product.discount &&
                <div className={styles.discount}>
                  <p>{product.discount_percentage}%</p>
                </div>
              }
              <div className={styles.priceBlockDetail}>
                <p className={styles.productPriceDetail}>{product.price} сом</p>
                {product.old_price > 0 && (
                  <p className={styles.productOldPriceDetail}>
                    <span>{product.old_price} сом</span>
                  </p>
                )}
              </div>
              <button className={styles.btnDetail} onClick={() => handleAddToCart(product.id)}>
                Купить
              </button>
              </div>

              <div className={styles.specifications}>
              <h3 className={styles.sp}>Характеристика :</h3>
              <p className={styles.specificationsText}>{product.specifications}</p>
          </div>
            </div>

          </div>


          <div>
            <h2 className={styles.descriptionTitle}>Описание</h2>
            <p className={styles.productDescription}>{product.description}</p>
          </div>
        </div>
      )}
      {activeTab === 'description' && (
        <div>
          <h2 className={styles.descriptionTitle}>Описание</h2>
          <p className={styles.productDescription}>{product.description}</p>
        </div>
      )}

      {activeTab === 'warranty' && product.warranty && (
        <div>
          <h2 className={styles.warrantyTitle}>Гарантия</h2>
          <p
            className={styles.warrantyDescription}
            dangerouslySetInnerHTML={{ __html: formatWarrantyText(product.warranty.description) }}
          />
        </div>
      )}


      <h2 className={styles.brandTitle}>Похожие товары от {brand?.name}</h2>
      {brandProducts.length > 0 && (
      <Slider {...createSliderSettings(brandProducts.length)} className={styles.ls}>
      {brandProducts.map(brandProduct => (
          <div key={brandProduct.id}>
            <div className={styles.productCard}>
              {brandProduct.discount && <p className={styles.discountL}>{brandProduct.discount_percentage}%</p>}
              <Link to={`/product/${brandProduct.id}`}>
                <img src={getProductImage(brandProduct.id)} alt={brandProduct.name} className={styles.productImage} />
                <h3 className={styles.productTitle}>{brandProduct.name}</h3>
              </Link>
              <div className={styles.priceBlock}>
                <div className="">
                  {brandProduct.old_price > 0 && (
                    <p className={styles.productOldPrice}>
                      <span>{brandProduct.old_price} сом</span>
                    </p>
                  )}
                  <p className={styles.productPrice}>{brandProduct.price} сом</p>
                </div>
                <button className={styles.btn} onClick={() => handleAddToCart(brandProduct.id)}>
                  <BsCart2 />
                </button>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      )}
      <MobileMenu className={styles.mobileMenu} />
      <ToastContainer />
    </div>
  );
};

export default ProductDetail;
