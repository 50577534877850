import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from './Header.module.css';
import { FaSearch } from "react-icons/fa";
import { IoCart, IoClose, IoHome } from "react-icons/io5";
import { BiWinkSmile } from "react-icons/bi";
import { FaUser } from "react-icons/fa";
import logo from '../../images/snapedit_1721337399409.png';
import logoPhone from '../../images/webbuy-logo-name.png';
import { Link } from 'react-router-dom';

export const Header = () => {
  const [token, setToken] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [products, setProducts] = useState([]);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    setToken(storedToken);
  }, []);

  const fetchCartDetails = async () => {
    if (!token) {
      return;
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/order/basket/detail/`, {
        headers: {
          Authorization: `token ${token}`
        }
      });
      setCartItems(response.data.items);
      fetchProducts(response.data.items);
    } catch (error) {
      console.error('Error fetching cart details', error);
    }
  };

  const fetchProducts = async (items) => {
    try {
      const promises = items.map(item =>
        axios.get(`${process.env.REACT_APP_MAIN_URL}/product/${item.product}/`)
      );
      const responses = await Promise.all(promises);
      setProducts(responses.map(response => response.data));
    } catch (error) {
      console.error('Error fetching product details', error);
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
    fetchCartDetails();
  };

  const handleCloseModal = (e) => {
    if (e.target.classList.contains(styles.overlay)) {
      setIsModalOpen(false);
    }
  };

  const fetchProductFile = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/file`);
      setFiles(response.data);
    } catch (error) {
      console.error('Error fetching the files data', error);
    }
  };

  const getProductImage = (productId) => {
    const file = files.find(file => file.product === productId && file.main_file);
    return file ? file.file : null;
  };

  const calculateTotal = () => {
    return cartItems.reduce((total, item) => {
      const product = products.find(p => p.id === item.product);
      if (product) {
        return total + product.price * item.quantity;
      }
      return total;
    }, 0);
  };

  useEffect(() => {
    fetchProductFile();
  }, []);

  return (
    <div>
      <div>
        <div className={styles.header}>
          <div className="container">
            <div className={styles.headerContainer}>
              <div className={styles.logo}>
                <Link to={'/'}> <img src={logoPhone} className={styles.headerLogo} alt="" /></Link>
                <Link to={'/'}> <img src={logoPhone} className={styles.headerLogoPhone} alt="" /></Link>
              </div>
              <div className={styles.navBlock}>
                <Link to={'/'} className={`${styles.lin} ${styles.dn}`} data-title="Главная"><IoHome className={styles.nav} /></Link>
                <Link to={'/search'} data-title="Поиск"><FaSearch className={styles.nav} /></Link>
                
                <Link to={token ? '/profile' : '/login'} className={`${styles.lin} ${styles.dn}`} data-title="Кабинет">
                  <FaUser className={styles.nav} />
                </Link>

                <Link className={styles.linL} data-title="Корзина" onClick={handleOpenModal}> <IoCart className={styles.nav} /></Link>
                <Link to={token ? '/basket' : '/login'} className={styles.linMobile} data-title="Корзина"> <IoCart className={styles.nav} /></Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className={styles.overlay} id="cartModal" onClick={handleCloseModal}>
          <div className={styles.modal}>
            <div className={styles.modalBody}>
              {cartItems.length > 0 ? (
                cartItems.map(item => {
                  const product = products.find(p => p.id === item.product);
                  if (!product) {
                    return null;
                  }
                  return (
                    <div key={item.id} className={styles.cartItem}>
                      <div className={styles.cartItemImag}>
                        <img
                          src={getProductImage(product.id)}
                          alt={product.name}
                          className={styles.productImage}
                        />
                      </div>
                      <div className={styles.cartItemContent}>
                        <p className={styles.cartItemTitle}>{product.name}</p>
                        <div className={styles.cartItemPrice}>
                          <p>{item.quantity}</p>
                          <p>{product.price} сом</p>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className={styles.noProducts}>
                  <p>Корзина пуста</p>
                  <BiWinkSmile />
                </div>
              )}
            </div>
            <div className={styles.modalFooter}>
              <p className={styles.total}>Итого: {calculateTotal()} сом</p>
              <Link to={token ? '/basket' : '/login'} className={styles.checkoutButton}>Перейти в корзину</Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
