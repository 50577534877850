import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import styles from './CategoryPage.module.css';
import { BsCart2 } from "react-icons/bs";
import { Loader } from '../../components/Loader/Loader';
import { IoCloseSharp, IoMenuSharp } from 'react-icons/io5';
import { MobileMenu } from '../../components/MobileMenu/MobileMenu';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const CategoryPage = () => {
  const { id } = useParams();
  const [products, setProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState([]);
  const [brands, setBrands] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [cartItems, setCartItems] = useState([]);



  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const fetchProductFile = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/file`);
      setFiles(response.data);
    } catch (error) {
      console.error('Error fetching the files data', error);
    }
  };

  const getProductImage = (productId) => {
    const file = files.find(file => file.product === productId && file.main_file);
    return file ? file.file : null;
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Прокрутка вверх при каждом изменении id

    const fetchCategoryProducts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/category/${id}/`);
        const categoryData = response.data;

        const categoryProducts = categoryData.products;

        setProducts(categoryProducts);
        setAllProducts(categoryProducts);
        setBrands(categoryData.brands);
        setSubcategories(categoryData.subcategories);
        setLoading(false);
      } catch (error) {
        console.error('Ошибка при получении товаров категории', error);
        setLoading(false);
      }
    };

    fetchCategoryProducts();
    fetchProductFile();
  }, [id]);

  const handleBrandChange = (brandId) => {
    setSelectedBrands((prevSelectedBrands) => {
      if (prevSelectedBrands.includes(brandId)) {
        return prevSelectedBrands.filter((id) => id !== brandId);
      } else {
        return [...prevSelectedBrands, brandId];
      }
    });
  };

  const handleSubcategoryChange = (subcategoryId) => {
    setSelectedSubcategories((prevSelectedSubcategories) => {
      if (prevSelectedSubcategories.includes(subcategoryId)) {
        return prevSelectedSubcategories.filter((id) => id !== subcategoryId);
      } else {
        return [...prevSelectedSubcategories, subcategoryId];
      }
    });
  };

  const handleAddToCart = async (productId) => {
    const token = localStorage.getItem('token');
    if (!token) {
      toast.error('Пожалуйста, пройдите регистрацию');
      return;
    }

    try {
      const existingItem = cartItems.find(item => item.product === productId);

      if (existingItem) {
        await axios.put(
          `${process.env.REACT_APP_MAIN_URL}/order/basket/items/${existingItem.id}/update/`,
          { quantity: existingItem.quantity + 1 },
          { headers: { Authorization: `token ${token}` } }
        );
        setCartItems(cartItems.map(item =>
          item.product === productId
            ? { ...item, quantity: item.quantity + 1 }
            : item
        ));
        toast.success('Количество товара обновлено в корзине');
      } else {
        const response = await axios.post(
          `${process.env.REACT_APP_MAIN_URL}/order/basket/items/create/`,
          { product: productId, quantity: 1 },
          { headers: { Authorization: `token ${token}` } }
        );
        setCartItems([...cartItems, { ...response.data }]);
        toast.success('Товар успешно добавлен в корзину');
      }
    } catch (error) {
      console.error('Error in handleAddToCart:', error);
      toast.error('Ошибка при добавлении товара в корзину');
    }
  };

  useEffect(() => {
    const filteredProducts = allProducts.filter((product) => {
      const matchesBrand = selectedBrands.length === 0 || selectedBrands.includes(product.brand);
      const matchesSubcategory = selectedSubcategories.length === 0 || selectedSubcategories.includes(product.subcategory);
      return matchesBrand && matchesSubcategory;
    });

    setProducts(filteredProducts);
  }, [selectedBrands, selectedSubcategories, allProducts]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={styles.categoryPage}>
      <button onClick={toggleMenu} className={styles.burgerMenu}>
        {isMenuOpen ? <IoCloseSharp /> : <IoMenuSharp />} {/* Иконка изменяется */}
      </button>
      <div className={`${styles.categorySidebar} ${isMenuOpen ? styles.open : ''}`}>
      <div className={styles.sidebar}>          
        <h3 className={styles.brandContainer}>Бренды</h3>
        <div>
          {brands.map((brand) => (
            <div key={brand.id} className={styles.brand}>
              <input
                type="checkbox"
                className={styles.checkbox}
                onChange={() => handleBrandChange(brand.id)}
                checked={selectedBrands.includes(brand.id)}
              />
              <h4 className={styles.brandName}>{brand.name}</h4>
            </div>
          ))}
        </div>
        <h3 className={styles.subcategoryContainer}>Подкатегории</h3>
        <div>
          {subcategories.map((subcategory) => (
            <div key={subcategory.id} className={styles.subcategory}>
              <input
                type="checkbox"
                className={styles.checkbox}
                onChange={() => handleSubcategoryChange(subcategory.id)}
                checked={selectedSubcategories.includes(subcategory.id)}
              />
              <h4 className={styles.subcategoryName}>{subcategory.name}</h4>
            </div>
          ))}
        </div>
        </div>
      </div>
      <div className={styles.productContainer}>
        {products.map((product) => (
          <div key={product.id} className={styles.productCard}>
            {product.discount && (
              <div className={styles.ds}>
                <p className={styles.discountL}>
                  {product.discount_percentage}%
                </p>
              </div>
            )}
            <Link to={`/product/${product.id}`} className={styles.ph}>
              <img
                src={getProductImage(product.id)}
                alt={product.name}
                className={styles.productImage}
              />
              <h3 className={styles.productTitle}>{product.name}</h3>
            </Link>
            <div className={styles.priceBlock}>
              <div className="">
                {product.old_price > 0 && (
                  <p className={styles.productOldPrice}>
                    <span>{product.old_price} сом</span>
                  </p>
                )}
                <p className={styles.productPrice}>
                  {product.price} сом
                </p>
              </div>
              <button className={styles.btn} onClick={() => handleAddToCart(product.id)}><BsCart2 /></button>
              </div>
          </div>
        ))}
      </div>
      <MobileMenu />
      <ToastContainer />
    </div>
  );
  
};

export default CategoryPage;
