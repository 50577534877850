import React, { useEffect } from 'react'
import ProductsList from '../../components/ProductList/ProductList'
import Category from '../../components/Category/Category'
import styles from './Main.module.css'
import { MobileMenu } from '../../components/MobileMenu/MobileMenu'
import { ToastContainer } from 'react-toastify'

export const Main =() => {
  useEffect(() => {
    window.scrollTo(0, 0); // Прокрутка вверх при каждом изменении id
  }, []);

    return (
    <div className={styles.main}>
        <MobileMenu />
        <Category />
        <ProductsList />
        <ToastContainer />

    </div>
  )
}
